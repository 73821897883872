import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import coloradasVirgen1 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_1.png';
import coloradasVirgen3 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_3.png';
import coloradasVirgen4 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_4.png';
import coloradasVirgen5 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_5.png';
import coloradasVirgen6 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_6.png';
import coloradasVirgen7 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_7.png';
import coloradasVirgen8 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_8.png';
import coloradasVirgen9 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_9.png';
import coloradasVirgen10 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_10.png';
import coloradasVirgen11 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_11.png';
import coloradasVirgen12 from '@/assets/hemeroteca/Hemeroteca_Coloradas_de_la_Virgen_12.png';
import denuncias1 from '@/assets/denuncias/1.png';
import denuncias2 from '@/assets/denuncias/2.png';
import denuncias3 from '@/assets/denuncias/3.png';
import denuncias4 from '@/assets/denuncias/4.png';
import denuncias5 from '@/assets/denuncias/5.png';
import denuncias6 from '@/assets/denuncias/6.png';
import denuncias7 from '@/assets/denuncias/7.png';
import denuncias8 from '@/assets/denuncias/8.png';
import arbolHemerotecaImg from '@/assets/hemeroteca/Hemeroteca_Arbol_IZQ.svg';
import arbolDenunciasImg from '@/assets/denuncias/Denuncias_Arbol_DER-01-01.svg';
import investigationsJson from '@/assets/json/investigations.json';
import imagesJson from '@/assets/json/images.json';
import videosJson from '@/assets/json/videos.json';
import interviewsJson from '@/assets/json/interviews.json';

import createInvestigationAdapter from '../app/investigation/investigation.adapter';
import createImageAdapter from '../app/image/image.adapter';
import createInteractionObjectAdapter from '../app/interaction-object/interaction-object.adapter';

const SIERRA_TARAHUMARA_INVESTIGATION_ID = 4;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    investigations: [],
    images: [],
    interviews: [],
    videos: [],
    selectedInvestigation: null,
    interactionObjects: [],
    loadingInvestigations: true,
    soundEnabled: false,
    mobileMenuEnabled: false,
    pages: [
      coloradasVirgen1,
      coloradasVirgen3,
      coloradasVirgen4,
      coloradasVirgen5,
      coloradasVirgen6,
      coloradasVirgen7,
      coloradasVirgen8,
      coloradasVirgen9,
      coloradasVirgen10,
      coloradasVirgen11,
      coloradasVirgen12,
    ],

    pagesDenuncias: [
      denuncias1,
      denuncias2,
      denuncias3,
      denuncias4,
      denuncias5,
      denuncias6,
      denuncias7,
      denuncias8,
    ],

    selectedInteractionObject: null,
    tooltip: {
      show: false,
      text: '',
    },
  },

  getters: {
    getInvestigations: (state) => state.investigations,

    getImages: (state) => state.images,

    getInterviews: (state) => state.interviews,

    getVideos: (state) => state.videos,

    getSelectedInvestigation: (state) => state.selectedInvestigation,

    getInteractionObjects: (state) => {
      const filteredInteractionObjects = state.interactionObjects.filter(
        (interactionObject) => interactionObject.investigationId === state.selectedInvestigation.id,
      );

      return filteredInteractionObjects.map((interactionObject, index) => ({
        ...interactionObject,
        id: `entity-${index}`,
      }));
    },

    getLoadingInvestigations: (state) => state.loadingInvestigations,

    getSoundEnabled: (state) => state.soundEnabled,

    getMobileMenuEnabled: (state) => state.mobileMenuEnabled,

    getPages: (state) => state.pages,

    getPagesDenuncias: (state) => state.pagesDenuncias,

    getSelectedInteractionObject: (state) => state.selectedInteractionObject,

    getInvestigationBackgroundImage: (state) => {
      if (state.selectedInvestigation) {
        return `${state.selectedInvestigation.backgroundImage}`;
      }

      return '';
    },

    getInvestigationBackgroundAudio: (state) => {
      if (state.selectedInvestigation) {
        return `${state.selectedInvestigation.backgroundAudio}`;
      }

      return '';
    },

    getTooltip: (state) => state.tooltip,
  },

  mutations: {
    SET_INVESTIGATIONS: (state, payload) => {
      state.investigations = payload;
    },

    SET_IMAGES: (state, payload) => {
      state.images = payload;
    },

    SET_INTERVIEWS: (state, payload) => {
      state.interviews = payload;
    },

    SET_VIDEOS: (state, payload) => {
      state.videos = payload;
    },

    SET_SELECTED_INVESTIGATION: (state, payload) => {
      state.selectedInvestigation = payload;
    },

    SET_INTERACTION_OBJECTS: (state) => {
      const interactionObjects = state.investigations.map((investigation) => ({
        title: investigation.name,
        type: 'investigation',
        body: investigation.content,
        icon: investigation.icon,
        model: investigation.model,
        position: investigation.position,
        rotation: investigation.rotation,
        scale: investigation.scale,
        isSvg: investigation.isSvg,
        investigationId: investigation.id,
      }));

      state.images.forEach((image) => {
        interactionObjects.push(createInteractionObjectAdapter(image, 'image'));
      });

      state.interviews.forEach((interview) => {
        interactionObjects.push(createInteractionObjectAdapter(interview, 'interview'));
      });

      state.videos.forEach((video) => {
        interactionObjects.push(createInteractionObjectAdapter(video, 'video'));
      });

      state.interactionObjects = interactionObjects;

      state.interactionObjects.push({
        title: 'Hemeroteca',
        type: 'hemeroteca',
        icon: arbolHemerotecaImg,
        model: arbolHemerotecaImg,
        position: '8.3 -1.1 2.6',
        rotation: '0 80 0',
        scale: '6 6 6',
        isSvg: true,
        investigationId: SIERRA_TARAHUMARA_INVESTIGATION_ID,
      });
      state.interactionObjects.push({
        title: 'Denuncias',
        type: 'denuncias',
        icon: arbolDenunciasImg,
        model: arbolDenunciasImg,
        position: '0.5 -1.1 8.2',
        rotation: '0 0 0',
        scale: '6 6 6',
        isSvg: true,
        investigationId: SIERRA_TARAHUMARA_INVESTIGATION_ID,
      });
    },

    SET_LOADING_INVESTIGATIONS: (state, payload) => {
      state.loadingInvestigations = payload;
    },

    SET_SOUND_ENABLED: (state, payload) => {
      state.soundEnabled = payload;
    },

    SET_MOBILE_MENU_ENABLED: (state, payload) => {
      state.mobileMenuEnabled = payload;
    },

    SET_SELECTED_INTERACTION_OBJECT: (state, payload) => {
      state.selectedInteractionObject = payload;
    },

    SET_TOOLTIP(state, payload) {
      state.tooltip = payload;
    },

  },

  actions: {
    async fetchInvestigations({ commit }) {
      const investigations = investigationsJson.data.map(
        (investigation) => createInvestigationAdapter(investigation),
      );
      commit('SET_INVESTIGATIONS', investigations);
    },

    async fetchImages({ commit }) {
      const images = imagesJson.data.map(
        (image) => createImageAdapter(image),
      );
      commit('SET_IMAGES', images);
    },

    async fetchInterviews({ commit }) {
      const interviews = interviewsJson.data.map(
        (interview) => createImageAdapter(interview),
      );
      commit('SET_INTERVIEWS', interviews);
    },

    async fetchVideos({ commit }) {
      const videos = videosJson.data.map(
        (video) => createImageAdapter(video),
      );
      commit('SET_VIDEOS', videos);
    },
  },

  plugins: [new VuexPersistence().plugin],
});
