const createInteractionObjectAdapter = (interactionObjectDto, interactionObjectMediaType) => ({
  title: interactionObjectDto.name,
  type: interactionObjectMediaType,
  body: interactionObjectMediaType === 'investigation' ? interactionObjectDto.content : null,
  icon: interactionObjectDto.icon,
  model: interactionObjectDto.model,
  position: interactionObjectDto.position,
  rotation: interactionObjectDto.rotation,
  scale: interactionObjectDto.scale,
  url: interactionObjectDto.url,
  isSvg: interactionObjectDto.isSvg,
  investigationId: interactionObjectDto.investigationId,
});

export default createInteractionObjectAdapter;
